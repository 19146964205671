import React from "react"
import Meta from "../utilities/seo"

const Error = () => {
  return (
    <>
      <Meta
        title="404"
        image={{
          url: "",
          height: "",
          width: "",
        }}
        description=""
      />
      <div>404</div>
    </>
  )
}


export default  Error